import React from "react";
import { MeasuresDateFilter } from "components/MeasuresDateFilter";
import CONSTANTS from "common/constants";
import config from "config";
import { MeasuresEmptyStateWrapper } from "./styled";

interface Props {
    add: any;
    isEditable: boolean;
    isAddMeasuresInProgress: boolean;
    measureLock?: boolean;
    activeServiceYear?: any;
    categoryId?: any;
}

const MeasureEmptyState = (props: Props) => {
    return (
        <MeasuresEmptyStateWrapper className="container measure-emptystate-container">
            {props.isEditable ? (
                <div className="measure-emptystate d-flex justify-content-center flex-column text-center align-items-center py-4">
                    {props.activeServiceYear ==
                        CONSTANTS.CURRENT_SERVICE_YEAR_ONWARDS &&
                    props.categoryId == CONSTANTS.EMS_PROGRAM_CATEGORY_ID &&
                    (config.env === "stg" ||
                        config.env === "prod" ||
                        config.env === "preprod") ? (
                        <>
                            <img
                                src="/images/folder-lock-icon.svg"
                                alt="folder icon for e m s"
                                className="mb-1 folder-icon"
                            />
                            <p className="pt-4 mb-0">
                                <span className="measure-disabled-text">
                                    Create Measure temporarily disabled.
                                </span>
                                <br />
                                <span>Please contact AHA admin</span>
                            </p>
                        </>
                    ) : (
                        <>
                            <img
                                src="/images/folder-icon.svg"
                                alt="folder icon"
                                className="mb-5 folder-icon"
                            />
                            <p className="pt-4 mb-0">
                                No measures available
                                <br /> Click below to add
                            </p>
                        </>
                    )}
                    <button
                        className={`btn btn-primary btn-round create-measure-btn ${
                            props.isAddMeasuresInProgress
                                ? CONSTANTS.BUTTON_SPINNER
                                : ""
                        }`}
                        onClick={props.add}
                        disabled={
                            props.activeServiceYear ==
                                CONSTANTS.CURRENT_SERVICE_YEAR_ONWARDS &&
                            props.categoryId ==
                                CONSTANTS.EMS_PROGRAM_CATEGORY_ID &&
                            (config.env === "stg" ||
                                config.env === "prod" ||
                                config.env === "preprod")
                                ? true
                                : props.isAddMeasuresInProgress ||
                                  props.measureLock
                        }
                    >
                        Create Measures
                    </button>
                </div>
            ) : (
                <div className="measure-emptystate d-flex justify-content-center flex-column text-center align-items-center py-4">
                    <img
                        src="/images/edit-block-icon.svg"
                        alt="folder icon"
                        className="mb-5 block-edit-icon"
                    />
                    <p className="pt-4">
                        Timeline for creating measures for the this quarter has
                        expired
                    </p>
                </div>
            )}
        </MeasuresEmptyStateWrapper>
    );
};

export default MeasureEmptyState;
