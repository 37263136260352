import { jsPDF as JsPdf } from "jspdf";
import html2canvas from "html2canvas";
import CONSTANTS from "common/constants";
import { getColorForSeries } from "./googleChartUtils";

let redirectInd = true;

export function getTokenPlayload(token: string) {
    const base64Url = token?.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
}

export async function sleep(time: number) {
    return new Promise((resolve, reject) => {
        setTimeout(function () {
            resolve(true);
        }, time);
    });
}

export function validJSON(JSONString: string) {
    try {
        JSON.parse(JSONString);
        return true;
    } catch (e) {
        return false;
    }
}

export const getCurrentQuarter = () => {
    const month = new Date().getMonth();
    if (month < 3) {
        return 1;
    }
    if (month < 6) {
        return 2;
    }
    if (month < 9) {
        return 3;
    }
    return 4;
};

export const parseJwt = (token: any) => {
    if (token) {
        const base64Url = token?.split(".")[1];
        const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                ?.split("")
                .map((c) => {
                    return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
                })
                .join("")
        );
        return JSON.parse(jsonPayload);
    }
    return {};
};

export const getQuarterStartAndEndDate = (
    year: number,
    quarter: number,
    emsInd?: boolean
) => {
    if (emsInd) {
        return `${year}-01-01/${year}-12-31`;
    }
    switch (quarter) {
        case 1:
            return `${year}-01-01/${year}-03-31`;
        case 2:
            return `${year}-04-01/${year}-06-30`;
        case 3:
            return `${year}-07-01/${year}-09-30`;
        case 4:
            return `${year}-10-01/${year}-12-31`;
        default:
            return null;
    }
};

export const getIntervalStartAndEndDate = (
    fromYear: number,
    fromQuarter: number,
    toYear: number,
    toQuarter: number
) => {
    let startDate;
    let endDate;
    switch (fromQuarter) {
        case 1:
            startDate = `${fromYear}-01-01`;
            break;
        case 2:
            startDate = `${fromYear}-04-01`;
            break;
        case 3:
            startDate = `${fromYear}-07-01`;
            break;
        case 4:
            startDate = `${fromYear}-10-01`;
            break;
        default:
            startDate = "";
    }

    switch (toQuarter) {
        case 1:
            endDate = `${toYear}-03-31`;
            break;
        case 2:
            endDate = `${toYear}-06-30`;
            break;
        case 3:
            endDate = `${toYear}-09-30`;
            break;
        case 4:
            endDate = `${toYear}-12-31`;
            break;
        default:
            endDate = "";
    }
    return `${startDate}/${endDate}`;
};

export const getAllYears = (fromYear: number, toYear: number) => {
    const allYears = [];
    for (let year = fromYear; year <= toYear; year++) {
        allYears.push(year);
    }
    return allYears;
};

export const getFIleSize = (fileSize: number) => {
    const fSExt = ["Bytes", "KB", "MB", "GB"];
    let i = 0;
    while (fileSize > 900) {
        fileSize /= 1024;
        i++;
    }
    return `${Math.round(fileSize * 100) / 100} ${fSExt[i]}`;
};

export const dateToLocaleDateString = (date: string) => {
    return new Date(`${date}`).toLocaleDateString();
};

export const dateToLocaleTimeString = (date: string) => {
    return new Date(`${date}`).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h12",
    });
};

export const dateToYYYYMMDDFormatString = (date: any) => {
    const dateObj = new Date(date).toISOString();
    return dateObj?.split("T")[0];
};

export const localDateToYYYYMMDDFormatString = (date: any) => {
    const monthArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    let dateObj = new Date(date);

    if (typeof date === "string") {
        if (date.indexOf("T") > -1) {
            dateObj = new Date(date);
        } else {
            dateObj = new Date(`${date}T00:00:00`);
        }
    }
    const month =
        dateObj.getMonth() + 1 <= 9
            ? `0${dateObj.getMonth() + 1}`
            : dateObj.getMonth() + 1;
    if (typeof month == "string") {
        return `${dateObj.getFullYear()}-${
            monthArray[parseInt(month) - 1]
        }-${`0${dateObj.getDate()}`.slice(-2)}`;
    }
    return `${dateObj.getFullYear()}-${
        monthArray[month - 1]
    }-${`0${dateObj.getDate()}`.slice(-2)}`;
};

export function getQueryVariable(variable: string) {
    const query = window.location.search.substring(1);
    const vars = query?.split("&");
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i]?.split("=");
        if (pair[0] == variable) {
            return pair[1].toString();
        }
    }
    return undefined;
}

export const dateToYYYYMonthNameDD = (date: any) => {
    return `${new Date(date).getFullYear()} ${new Date(date).toLocaleString(
        "default",
        { month: "long" }
    )} ${new Date(date).getDate()}`;
};

export const calculatNumOfDaysBetweenDates = (
    currentDate: any,
    expireDate: any
) => {
    const year: any = currentDate.getFullYear();
    const month: any = `0${currentDate.getMonth() + 1}`.slice(-2);
    const day: any = `0${currentDate.getDate()}`.slice(-2);
    currentDate = `${year}-${month}-${day}`;
    const timeDifference =
        new Date(dateToYYYYMMDDFormatString(expireDate)).getTime() -
        new Date(
            dateToYYYYMMDDPlaceholderFormatStringISO(currentDate)
        ).getTime();

    const daysDifference = Math.round(timeDifference / (1000 * 3600 * 24)) + 1;
    if (daysDifference === 1) {
        return 1;
    }
    if (daysDifference <= 0) return 0;
    return daysDifference;
};

export const dateToYYYYMMMDDFormat = (date: any) => {
    const dateString = new Date(date);
    const year = dateString.getFullYear();
    const month = dateString.toLocaleString("default", { month: "short" });
    const day = String(dateString.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
};
export const expireDataRowStatus = (expiresOn: any, tab: any) => {
    if (expiresOn && tab !== CONSTANTS.DEACTIVATED) {
        const days = calculatNumOfDaysBetweenDates(new Date(), expiresOn);
        if (days >= 1 && days <= 50) {
            return "aui-table-status-warning";
        }
        if (days === 0) {
            return "aui-table-status-danger";
        }
    }
    return hospitalApprovalStatus(tab);
};

export const calculateExtendedDate = (dueDate: any, gracePeriod: any) => {
    return new Date(
        new Date(dueDate).getTime() + Number(gracePeriod) * 24 * 60 * 60 * 1000
    )
        .toISOString()
        ?.split("T")[0];
};

export const calculateGracePeriod = (extendedDate: any, dueDate: any) => {
    return Math.ceil(
        (new Date(extendedDate).getTime() - new Date(dueDate).getTime()) /
            (1000 * 60 * 60 * 24)
    );
};

export function redirectToHomePage(sleepTime: number = 0) {
    if (redirectInd) {
        redirectInd = false;
        const docWindow: any = window;
        const roleCode = parseJwt(
            docWindow.localStorage.getItem("userAccessToken")
        ).role_code;
        localStorage.removeItem("userAccessToken");
        if (roleCode === CONSTANTS.USER_ROLES.REGISTRY_ADMIN) {
            docWindow.userManager.settings._post_logout_redirect_uri = `${docWindow.location.origin}/registry`;
        } else if (roleCode === CONSTANTS.USER_ROLES.CORPORATION_ADMIN) {
            docWindow.userManager.settings._post_logout_redirect_uri = `${docWindow.location.origin}/corporation`;
        }
        /* adding sleep so that the toast message is displayed on screen */
        if (sleepTime > 0) {
            sleep(sleepTime).then(() => {
                redirectInd = true;
                docWindow.userManager.signoutRedirect();
            });
        } else {
            redirectInd = true;
            docWindow.userManager.signoutRedirect();
        }
    }
}

export function hospitalApprovalStatus(approvalStatus: any) {
    let className = "";
    switch (approvalStatus) {
        case CONSTANTS.PENDING:
            className = "aui-table-status-pending";
            break;
        case CONSTANTS.REGISTRED:
            className = "aui-table-status-success";
            break;
        case CONSTANTS.RENEWING:
            className = "aui-table-status-success";
            break;
        case CONSTANTS.CERTIFIED:
            className = "aui-table-status-success";
            break;
        case CONSTANTS.AWARDED:
            className = "aui-table-status-success";
            break;
        case CONSTANTS.DEACTIVATED:
            className = "aui-table-status-deactivated";
            break;
        default:
            className = "aui-table-status-pending";
            break;
    }
    return className;
}

export function measureStatusClass(measureInd: any) {
    let className = "";
    switch (measureInd) {
        case CONSTANTS.MEASURE_STATUS.NO_DATA:
            className = "measure-no-data";
            break;
        case CONSTANTS.MEASURE_STATUS.SUBMITTED:
            className = "measure-submitted";
            break;
        case CONSTANTS.MEASURE_STATUS.SAVED:
            className = "measure-saved";
            break;
        default:
            className = "measure-no-data";
            break;
    }
    return className;
}

const modifyExportFacilityTemplate = (
    document: any,
    hospital: any,
    programData: any,
    registryQuestionData: any,
    registryInfo: any,
    agreementInfo: any,
    billingInfo: any,
    paymentInfo: any
) => {
    if (!document) return;
    const roleCode = parseJwt(
        localStorage.getItem("userAccessToken")
    ).role_code;
    document.getElementById("templateHeading").innerHTML =
        hospital && hospital.facilityName
            ? `Online Application - ${hospital.facilityName} `
            : "N/A";

    /* program data set starts */
    const parsedJsonFormData = JSON.parse(programData.programDetails);
    const jsonInput = parsedJsonFormData.jsonForm;
    let programContent = `<tr><th scope="row" className=""> Selected Program </th><td className="aui-row-td"> ${programData.programCategoryName} </td></tr>`;

    jsonInput.forEach((program: any) => {
        if (program.label && !(program.hideElement && program.value === "")) {
            programContent += `<tr><th> ${wordChange(program.label)} </th>`;
            if (program.type == "parent" && program.options) {
                programContent += `<td>`;
                program.options.forEach((item: any) => {
                    programContent += `<span class="prg-type">${
                        item.label || item.key
                    }: ${item.value || "N/A"} </span>`;
                });
                programContent += `</td></tr>`;
            } else if (program.type == "checkbox" && program.options) {
                programContent += `<td className="aui-row-td"> ${
                    program.value ? program.value.join(", ") : "N/A"
                } </td></tr>`;
            } else if (program.type == "multiSelect" && program.options) {
                const multiSelectValue =
                    program.value.length &&
                    program.value.map((item: any) => item.value);
                programContent += `<td className="aui-row-td"> ${
                    multiSelectValue.length
                        ? multiSelectValue.join(", ")
                        : "N/A"
                } </td></tr>`;
            } else {
                programContent += `<td className="aui-row-td">${
                    program.value || "N/A"
                }</td></tr>`;
            }
        }
    });

    if (registryQuestionData && registryInfo && registryInfo.isRegistryUsing) {
        programContent += `<tr><th>${wordChange(
            registryQuestionData
        )}</th><td>`;
        programContent += `${
            registryInfo.isRegistryUsing == "YES" ? "Yes" : "No"
        }</td></tr>`;

        if (
            registryQuestionData &&
            registryInfo &&
            registryInfo.isRegistryUsing &&
            registryInfo.isRegistryUsing == "YES"
        ) {
            programContent += `<tr><th> Registry Name </th><td>`;
            programContent += `${
                registryInfo.registryName == CONSTANTS.REGISTRY_OTHER
                    ? registryInfo.customRegistryName
                    : registryInfo.registryName
            } </td></tr>`;
        }
    }
    programContent += `<tr><th>Link your site to a corporate parent account?</th><td>`;
    programContent += `${
        programData && programData.corporationId ? "Yes" : "No"
    } </td></tr>`;

    if (programData && programData.corporationId) {
        programContent += `<tr><th>Corporation Name</th><td>`;
        programContent += `${
            programData && programData.corporationName
                ? programData.corporationName
                : "N/A"
        } </td></tr>`;
    }

    programContent += `<tr><th>Program Registered on</th><td>`;
    programContent += `${
        programData && programData.createdAt
            ? localDateToYYYYMMDDFormatString(programData.createdAt)
            : "N/A"
    } </td></tr>`;

    document.getElementById("pgm_details_tbody").innerHTML = null;
    if (document.getElementById("pgm_details_tbody")) {
        document.getElementById("pgm_details_tbody").innerHTML = programContent;
    }
    /* program data set ends */

    /* facility details set starts */
    document.getElementById("facilityName").innerHTML =
        hospital.facilityName || "N/A";
    document.getElementById("countryName").innerHTML =
        hospital.countryName || "N/A";
    document.getElementById("streetAddress").innerHTML =
        hospital.streetAddress || "N/A";
    document.getElementById("city").innerHTML = hospital.city || "N/A";
    document.getElementById("state").innerHTML = hospital.state || "N/A";
    document.getElementById("zipCode").innerHTML = hospital.zipCode || "N/A";
    document.getElementById("website").innerHTML = hospital.website || "N/A";
    document.getElementById("primaryContactName").innerHTML =
        `${hospital.primaryContactTitle} ${hospital.primaryContactName}` ||
        "N/A";
    document.getElementById("primaryContactPhoneNumber").innerHTML =
        hospital.primaryContactPhoneNumber || "N/A";
    document.getElementById("primaryEmail").innerHTML =
        hospital.primaryEmail || "N/A";
    document.getElementById("signatoryName").innerHTML =
        `${hospital.signatoryNameTitle} ${hospital.signatoryName}` || "N/A";
    document.getElementById("signatoryEmail").innerHTML =
        hospital.signatoryEmail || "N/A";
    document.getElementById("medicalDirectorName").innerHTML =
        hospital.medicalDirectorName
            ? `${hospital.medicalDirectorTitle} ${hospital.medicalDirectorName}`
            : "N/A";
    document.getElementById("medicalDirectorEmail").innerHTML =
        hospital.medicalDirectorEmail || "N/A";
    document.getElementById("healthRecord").innerHTML = hospital.healthRecord
        ? `Yes - ${hospital.erhName}`
        : "No";
    /* facility details set ends */

    /* agreement details set starts */
    document.getElementById("nameOfSigner").innerHTML =
        agreementInfo.signerName || "N/A";
    document.getElementById("titleOfSigner").innerHTML =
        agreementInfo.signerTitle || "N/A";
    document.getElementById("emailOfSigner").innerHTML =
        agreementInfo.signerEmail || "N/A";

    if (agreementInfo.signedDate) {
        if (agreementInfo.signedDate.includes("T00:00:00.000Z")) {
            document.getElementById("signedDate").innerHTML =
                dateToYYYYMMDDFormatStringISO(agreementInfo.signedDate);
        } else {
            document.getElementById("signedDate").innerHTML =
                localDateToYYYYMMDDFormatString(agreementInfo.signedDate);
        }
    } else {
        document.getElementById("signedDate").innerHTML = "N/A";
    }

    document.getElementById("participantTaxId").innerHTML =
        agreementInfo.participantTaxId || "N/A";
    if (
        agreementInfo.permissionEvents ||
        agreementInfo.permissionMedia ||
        agreementInfo.permissionBanners ||
        agreementInfo.permissionAds
    ) {
        if (!agreementInfo.permissionEvents) {
            document.getElementById("permissionEvents").style.display = "none";
        }
        if (!agreementInfo.permissionMedia) {
            document.getElementById("permissionMedia").style.display = "none";
        }
        if (!agreementInfo.permissionBanners) {
            document.getElementById("permissionBanners").style.display = "none";
        }
        if (!agreementInfo.permissionAds) {
            document.getElementById("permissionAds").style.display = "none";
        }
    } else {
        document.getElementById("permissionsAgreement").innerHTML = "N/A";
    }
    /* agreement details set ends */
    /* billing details set starts */
    document.getElementById("billedFor").innerHTML =
        billingInfo.facilityName || "N/A";
    document.getElementById("billingContactName").innerHTML =
        billingInfo.billingContactName || "N/A";
    document.getElementById("billingAddress").innerHTML =
        billingInfo.billingAddress || "N/A";
    document.getElementById("billedCity").innerHTML = billingInfo.city || "N/A";
    document.getElementById("billedState").innerHTML =
        billingInfo.state || "N/A";
    document.getElementById("billedCountryName").innerHTML =
        billingInfo.countryName || "N/A";
    document.getElementById("billedZipCode").innerHTML =
        billingInfo.zipCode || "N/A";
    document.getElementById("phoneNumber").innerHTML =
        billingInfo.phoneNumber || "N/A";
    document.getElementById("emailAddress").innerHTML =
        billingInfo.emailAddress || "N/A";
    /* billing details set ends */

    /* payment details set starts */

    document.getElementById("paymentStatus").innerHTML =
        paymentInfo.paymentStatus || "N/A";
    document.getElementById("modeOfPayment").innerHTML =
        paymentInfo.modeOfPayment || "N/A";

    if (paymentInfo.paymentDate) {
        if (
            paymentInfo.paymentDate.includes("T00:00:00.000Z") ||
            !paymentInfo.paymentDate.includes("T")
        ) {
            document.getElementById("paymentDate").innerHTML =
                dateToYYYYMMDDFormatStringISO(paymentInfo.paymentDate);
        } else {
            document.getElementById("paymentDate").innerHTML =
                localDateToYYYYMMDDFormatString(paymentInfo.paymentDate);
        }
    } else {
        document.getElementById("paymentDate").innerHTML = "N/A";
    }

    if (paymentInfo.paymentDueDate) {
        if (
            paymentInfo.paymentDueDate.includes("T00:00:00.000Z") ||
            !paymentInfo.paymentDueDate.includes("T")
        ) {
            document.getElementById("paymentDueDate").innerHTML =
                dateToYYYYMMDDFormatStringISO(paymentInfo.paymentDueDate);
        } else {
            document.getElementById("paymentDueDate").innerHTML =
                localDateToYYYYMMDDFormatString(paymentInfo.paymentDueDate);
        }
    } else {
        document.getElementById("paymentDueDate").innerHTML = "N/A";
    }

    document.getElementById("paymentExtentedDueDate").innerHTML =
        paymentInfo.extendedDatePayment
            ? localDateToYYYYMMDDFormatString(paymentInfo.extendedDatePayment)
            : paymentInfo.paymentDueDate && paymentInfo.gracePeriod
            ? calculateExtendedDateYYMMMDD(
                  paymentInfo.paymentDueDate,
                  paymentInfo.gracePeriod
              )
            : "N/A";
    document.getElementById("amountPaid").innerHTML = paymentInfo.amountPaid
        ? `${paymentInfo.amountPaid}`
        : "N/A";
    document.getElementById("amountPaidLabel").innerHTML =
        paymentInfo.paymentStatus == CONSTANTS.PENDING
            ? "Amount"
            : "Amount Paid";
    document.getElementById("paymentMadeBy").innerHTML =
        paymentInfo?.paymentMadeBy === CONSTANTS.USER_ROLES.CORPORATION_ADMIN
            ? `Corporation - ${paymentInfo?.paymentMadeByCorporation}`
            : paymentInfo?.paymentMadeBy === CONSTANTS.USER_ROLES.HOSPITAL_ADMIN
            ? `Organization - ${hospital.facilityName}`
            : "N/A";
    switch (paymentInfo.paymentVerifiedByRole) {
        case CONSTANTS.USER_ROLES.HOSPITAL_ADMIN: {
            document.getElementById(
                "paymentVerifiedBy"
            ).innerHTML = `${paymentInfo.paymentVerifiedBy} (User)`;
            break;
        }
        case CONSTANTS.USER_ROLES.CORPORATION_ADMIN: {
            document.getElementById(
                "paymentVerifiedBy"
            ).innerHTML = `${paymentInfo.paymentVerifiedBy} (Corporation)`;
            break;
        }
        case CONSTANTS.USER_ROLES.AHA_ADMIN: {
            document.getElementById(
                "paymentVerifiedBy"
            ).innerHTML = `${paymentInfo.paymentVerifiedBy} (AHA Admin)`;
            break;
        }
        default: {
            document.getElementById("paymentVerifiedBy").innerHTML = "N/A";
            break;
        }
    }
    if (
        hospital?.countryCode === CONSTANTS.DOMESTIC_COUNTRY_CODE &&
        roleCode === CONSTANTS.USER_ROLES.CORPORATION_ADMIN
    ) {
        const elements = document.getElementsByClassName("payment-table");
        if (elements.length > 0) {
            while (elements[0]) {
                elements[0].remove();
            }
        }
    }
    /* payment details set ends */
};

const modifyExportEMSTemplate = (
    document: any,
    hospital: any,
    programData: any,
    agreementInfo: any
) => {
    if (!document) return;
    document.getElementById("templateHeading").innerHTML =
        hospital && hospital.facilityName
            ? `Online Application - ${hospital.facilityName} `
            : "NA";

    /* program data set starts */
    const parsedJsonFormData = JSON.parse(programData.programDetails);
    const jsonInput = parsedJsonFormData.jsonForm;
    let programContent = `<tr><th scope="row" className=""> Selected Program </th><td className="aui-row-td"> ${programData.programCategoryName} </td></tr>`;

    programContent += `<tr><th scope="row" className=""> American Heart Association (AHA) EMS ID</th><td className="aui-row-td"> ${programData.emsId} </td></tr>`;

    programContent += `<tr><th scope="row" className="">Prior Year Award(2023)</th><td className="aui-row-td">
     ${
         programData.priorYearAward === null
             ? "No Award"
             : programData.priorYearAward
     } </td></tr>`;

    programContent += `<tr><th scope="row" className="">EMS Agency State Identification Number</th><td className="aui-row-td"> ${programData.emsAgencyStateIdNumber} </td></tr>`;

    programContent += `<tr><th scope="row" className=""> State associated with State ID above</th><td className="aui-row-td"> ${programData.emsAgencyStateId} </td></tr>`;

    jsonInput.forEach((program: any) => {
        if (program.label) {
            programContent += `<tr><th> ${wordChange(program.label)} </th>`;
            if (program.type == "parent" && program.options) {
                programContent += `<td>`;
                program.options.forEach((item: any) => {
                    programContent += `<span class="prg-type">${
                        item.label || item.key
                    }: ${item.value || "NA"} </span>`;
                });
                programContent += `</td></tr>`;
            } else if (program.type == "checkbox" && program.options) {
                programContent += `<td className="aui-row-td"> ${
                    program.value ? program.value.join(", ") : "NA"
                } </td></tr>`;
            } else if (program.type == "multiSelect" && program.options) {
                const multiSelectValue =
                    program.value.length &&
                    program.value.map((item: any) => item.value);
                programContent += `<td className="aui-row-td"> ${
                    multiSelectValue.length ? multiSelectValue.join(", ") : "NA"
                } </td></tr>`;
            } else {
                programContent += `<td className="aui-row-td">${
                    program.value || "NA"
                }</td></tr>`;
            }
        }
    });

    /* facility details set starts */
    document.getElementById("facilityName").innerHTML =
        hospital.facilityName || "NA";
    document.getElementById("countryName").innerHTML =
        hospital.countryName || "NA";
    document.getElementById("streetAddress").innerHTML =
        hospital.streetAddress || "NA";
    document.getElementById("city").innerHTML = hospital.city || "NA";
    document.getElementById("state").innerHTML = hospital.state || "NA";
    document.getElementById("zipCode").innerHTML = hospital.zipCode || "NA";
    document.getElementById("website").innerHTML = hospital.website || "NA";
    document.getElementById("primaryContactName").innerHTML =
        `${hospital.primaryContactTitle} ${hospital.primaryContactName}` ||
        "NA";
    document.getElementById("primaryContactPhoneNumber").innerHTML =
        hospital.primaryContactPhoneNumber || "NA";
    document.getElementById("primaryEmail").innerHTML =
        hospital.primaryEmail || "NA";

    document.getElementById("signatoryName").innerHTML =
        `${hospital.signatoryNameTitle} ${hospital.signatoryName}` || "N/A";
    document.getElementById("signatoryEmail").innerHTML =
        hospital.signatoryEmail || "NA";
    document.getElementById("medicalDirectorName").innerHTML =
        hospital.medicalDirectorName
            ? `${hospital.medicalDirectorTitle} ${hospital.medicalDirectorName}`
            : "NA";
    document.getElementById("medicalDirectorEmail").innerHTML =
        hospital.medicalDirectorEmail || "NA";
    document.getElementById("healthRecord").innerHTML = hospital.healthRecord
        ? `Yes - ${hospital.erhName}`
        : "No";
    /* facility details set ends */

    /* program data set starts */
    document.getElementById("pgm_details_tbody").innerHTML = null;
    if (document.getElementById("pgm_details_tbody")) {
        document.getElementById("pgm_details_tbody").innerHTML = programContent;
    }
    /* program data set ends */

    /* agreement details set starts */
    document.getElementById("nameOfSigner").innerHTML =
        agreementInfo.signerName || "NA";
    document.getElementById("titleOfSigner").innerHTML =
        agreementInfo.signerTitle || "NA";
    document.getElementById("emailOfSigner").innerHTML =
        agreementInfo.signerEmail || "NA";

    if (agreementInfo.signedDate) {
        if (agreementInfo.signedDate.includes("T00:00:00.000Z")) {
            document.getElementById("signedDate").innerHTML =
                dateToYYYYMMDDFormatStringISO(agreementInfo.signedDate);
        } else {
            document.getElementById("signedDate").innerHTML =
                localDateToYYYYMMDDFormatString(agreementInfo.signedDate);
        }
    } else {
        document.getElementById("signedDate").innerHTML = "NA";
    }

    if (
        agreementInfo.permissionEvents ||
        agreementInfo.permissionMedia ||
        agreementInfo.permissionBanners ||
        agreementInfo.permissionAds
    ) {
        if (!agreementInfo.permissionEvents) {
            document.getElementById("permissionEvents").style.display = "none";
        }
        if (!agreementInfo.permissionMedia) {
            document.getElementById("permissionMedia").style.display = "none";
        }
        if (!agreementInfo.permissionBanners) {
            document.getElementById("permissionBanners").style.display = "none";
        }
        if (!agreementInfo.permissionAds) {
            document.getElementById("permissionAds").style.display = "none";
        }
    } else {
        document.getElementById("permissionsAgreement").innerHTML = "NA";
    }

    document.getElementById("organizationPublications").innerHTML =
        agreementInfo.publicationName || "NA";
    /* agreement details set ends */
};

export async function exportDetails(
    hospital: any,
    programData: any,
    registryQuestionData: any,
    registryInfo: any,
    agreementInfo: any,
    billingInfo: any,
    paymentInfo: any
) {
    let pdfDocument = new JsPdf("p", "mm", "a4", true);
    const position = 10;
    const imgWidth = 200;
    const pageHeight = 300;
    const pagePaddingLeft = 5;
    const imageType = "PNG";
    let nextPosition = 10;
    let footerCanvas: any;

    const hiddenFrame: any = document.getElementsByClassName("hiddenFrame")[0];
    const hiddenFrameDoc = hiddenFrame.contentWindow.document;
    modifyExportFacilityTemplate(
        hiddenFrameDoc,
        hospital,
        programData,
        registryQuestionData,
        registryInfo,
        agreementInfo,
        billingInfo,
        paymentInfo
    );

    /* footer canvas creation */
    await html2canvas(hiddenFrameDoc.querySelector("footer"), {
        scale: 2,
    }).then((canvas: any) => {
        footerCanvas = canvas;
    });

    /* function to set footer on each new page at bottom */
    async function setFooter() {
        const footerCanvasImage = footerCanvas.toDataURL("image/png");
        const footerImgHeight =
            (footerCanvas.height * imgWidth) / footerCanvas.width;
        pdfDocument.addImage(
            footerCanvasImage,
            imageType,
            pagePaddingLeft,
            282,
            imgWidth,
            footerImgHeight,
            "",
            "FAST"
        );
    }

    /* canvas creation of header & appending header and footer canvases into pdf */
    await html2canvas(hiddenFrameDoc.getElementById("printElementHeader"), {
        scale: 2,
    }).then(async (headerCanvas: any) => {
        const imgData = headerCanvas.toDataURL("image/png");
        const imgHeight = (headerCanvas.height * imgWidth) / headerCanvas.width;
        pdfDocument.addImage(
            imgData,
            imageType,
            pagePaddingLeft,
            nextPosition,
            imgWidth,
            imgHeight,
            "",
            "FAST"
        );
        nextPosition += imgHeight + 10;
        setFooter();
    });

    /* canvas creation for each section/table content  */
    const printElementsArr: any =
        hiddenFrameDoc.body.getElementsByClassName("table");

    const elementArrValues: any = Object.values(printElementsArr);

    /* appending each section/table canvas into the PDF document */
    for (let index = 0; index < elementArrValues.length; index++) {
        await html2canvas(elementArrValues[index], {
            scale: 2,
        }).then(async (canvasElement: any) => {
            const imgData = canvasElement.toDataURL("image/png");
            const imgHeight =
                (canvasElement.height * imgWidth) / canvasElement.width;

            if (index > 0 && imgHeight + nextPosition + 20 > pageHeight) {
                pdfDocument.addPage();
                nextPosition = imgHeight + 20;

                pdfDocument.addImage(
                    imgData,
                    imageType,
                    pagePaddingLeft,
                    position,
                    imgWidth,
                    imgHeight,
                    "",
                    "FAST"
                );
                setFooter();
            } else {
                pdfDocument.addImage(
                    imgData,
                    imageType,
                    pagePaddingLeft,
                    nextPosition,
                    imgWidth,
                    imgHeight,
                    "",
                    "FAST"
                );
                nextPosition += imgHeight + 20;
            }
        });
    }

    /* remove iframe which appended to the main document */
    if (hiddenFrame && hiddenFrame.parentNode) {
        hiddenFrame.parentNode.removeChild(hiddenFrame);
    }

    function addWaterMark(doc: any) {
        const totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            const img = new Image();
            img.src =
                "./../../../../../../../export-template/export-facilit-watermark.png";
            doc.addImage(img, "png", 28, 70, 150, 150);
        }
        return doc;
    }
    pdfDocument = addWaterMark(pdfDocument);

    /* generate PDF file and download */
    pdfDocument.save(
        `${hospital.facilityName}-${
            programData.programCategoryName
        }-${localDateToYYYYMMDDFormatString(new Date())}.pdf`
    );
}

export async function exportEMSDetails(
    hospital: any,
    programData: any,
    agreementInfo: any
) {
    let pdfDocument = new JsPdf("p", "mm", "a4", true);
    const position = 10;
    const imgWidth = 200;
    const pageHeight = 300;
    const pagePaddingLeft = 5;
    const imageType = "PNG";
    let nextPosition = 10;
    let footerCanvas: any;

    const hiddenFrame: any = document.getElementsByClassName("hiddenFrame")[0];
    const hiddenFrameDoc = hiddenFrame.contentWindow.document;
    modifyExportEMSTemplate(
        hiddenFrameDoc,
        hospital,
        programData,
        agreementInfo
    );

    /* footer canvas creation */
    await html2canvas(hiddenFrameDoc.querySelector("footer"), {
        scale: 2,
    }).then((canvas: any) => {
        footerCanvas = canvas;
    });

    /* function to set footer on each new page at bottom */
    async function setFooter() {
        const footerCanvasImage = footerCanvas.toDataURL("image/png");
        const footerImgHeight =
            (footerCanvas.height * imgWidth) / footerCanvas.width;
        pdfDocument.addImage(
            footerCanvasImage,
            imageType,
            pagePaddingLeft,
            282,
            imgWidth,
            footerImgHeight,
            "",
            "FAST"
        );
    }

    /* canvas creation of header & appending header and footer canvases into pdf */
    await html2canvas(hiddenFrameDoc.getElementById("printElementHeader"), {
        scale: 2,
    }).then(async (headerCanvas: any) => {
        const imgData = headerCanvas.toDataURL("image/png");
        const imgHeight = (headerCanvas.height * imgWidth) / headerCanvas.width;
        pdfDocument.addImage(
            imgData,
            imageType,
            pagePaddingLeft,
            nextPosition,
            imgWidth,
            imgHeight,
            "",
            "FAST"
        );
        nextPosition += imgHeight + 10;
        setFooter();
    });

    /* canvas creation for each section/table content  */
    const printElementsArr: any =
        hiddenFrameDoc.body.getElementsByClassName("table");

    const elementArrValues: any = Object.values(printElementsArr);

    /* appending each section/table canvas into the PDF document */
    for (let index = 0; index < elementArrValues.length; index++) {
        await html2canvas(elementArrValues[index], {
            scale: 2,
        }).then(async (canvasElement: any) => {
            const imgData = canvasElement.toDataURL("image/png");
            const imgHeight =
                (canvasElement.height * imgWidth) / canvasElement.width;

            if (index > 0 && imgHeight + nextPosition + 20 > pageHeight) {
                pdfDocument.addPage();
                nextPosition = imgHeight + 20;

                pdfDocument.addImage(
                    imgData,
                    imageType,
                    pagePaddingLeft,
                    position,
                    imgWidth,
                    imgHeight,
                    "",
                    "FAST"
                );
                setFooter();
            } else {
                pdfDocument.addImage(
                    imgData,
                    imageType,
                    pagePaddingLeft,
                    nextPosition,
                    imgWidth,
                    imgHeight,
                    "",
                    "FAST"
                );
                nextPosition += imgHeight + 20;
            }
        });
    }

    /* remove iframe which appended to the main document */
    if (hiddenFrame && hiddenFrame.parentNode) {
        hiddenFrame.parentNode.removeChild(hiddenFrame);
    }

    function addWaterMark(doc: any) {
        const totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            const img = new Image();
            img.src =
                "./../../../../../../../export-template/export-facilit-watermark.png";
            doc.addImage(img, "png", 28, 70, 150, 150);
        }
        return doc;
    }
    pdfDocument = addWaterMark(pdfDocument);

    /* generate PDF file and download */
    pdfDocument.save(
        `${hospital.facilityName}-${
            programData.programCategoryName
        }-${localDateToYYYYMMDDFormatString(new Date())}.pdf`
    );
}

export function wordSplit(str: string) {
    return str && str.split("").join(" ");
}

export function wordChange(str: string) {
    const tmp = "Organization";
    str = str?.replace(/Facility/g, tmp);
    str = str?.replace(/facility/g, tmp.toLowerCase());
    return str;
}

export function paymentMadeBy(code: any, details: any, isEUP: any) {
    if (
        code == CONSTANTS.ALREADY_EXISTS &&
        details &&
        details.message == CONSTANTS.PAYMENT_COMPLETE_MSG
    ) {
        details.message = isEUP
            ? "Registration already completed by Corporation."
            : "Payment already made by the organization.";
        return details;
    }
    return details;
}

export function showModal() {
    document.querySelector(".modal-backdrop")?.classList.toggle("show");
    document.querySelector("body")?.classList.toggle("modal-open");
}

export const calculateExtendedDateYYMMMDD = (
    dueDate: any,
    gracePeriod: any
) => {
    const date = new Date(
        new Date(dueDate).getTime() + Number(gracePeriod) * 24 * 60 * 60 * 1000
    )
        .toISOString()
        ?.split("T")[0];
    return localDateToYYYYMMDDFormatString(date);
};

export const dateToYYYYMMDDFormatStringISO = (date: any) => {
    const dateObj = new Date(date).toISOString();
    return localDateToYYYYMMDDFormatString(dateObj?.split("T")[0]);
};

export const dateToYYYYMMDDPlaceholderFormatStringISO = (date: any) => {
    const dateObj = new Date(date).toISOString();
    return localDateToYYYYMMDDPlaceholderFormat(dateObj?.split("T")[0]);
};

export const localDateToYYYYMMDDPlaceholderFormat = (date: any) => {
    let dateObj = new Date(date);

    if (typeof date === "string") {
        if (date.indexOf("T") > -1) {
            dateObj = new Date(date);
        } else {
            dateObj = new Date(`${date}T00:00:00`);
        }
    }
    const month =
        dateObj.getMonth() + 1 <= 9
            ? `0${dateObj.getMonth() + 1}`
            : dateObj.getMonth() + 1;
    return `${dateObj.getFullYear()}-${month}-${`0${dateObj.getDate()}`.slice(
        -2
    )}`;
};

export const redirectionForErrorCode = (
    path: any,
    error: any,
    history: any,
    sleepTime: number,
    showModalInd?: any
) => {
    if (error.code == CONSTANTS.FORBIDDEN) {
        sleep(sleepTime).then(() => {
            history.push(path);
        });
    }
    if (error.code !== CONSTANTS.BAD_REQUEST && showModalInd) {
        showModal();
    }
};

export const skipToMainContent = () => {
    const mainElm = document.getElementById("maincontent");
    if (mainElm !== null) {
        mainElm.tabIndex = -1;
        mainElm.focus();
    }
};

export const exportFile = (data: any, programName: any) => {
    const bufnew = {
        type: "Buffer",
        data,
    };
    const date = new Date()
        .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
        })
        .split(" ")
        .join("-");
    const buf = new Uint8Array(bufnew.data);
    const buftype = "application/vnd.ms-excel";
    const blob = new Blob([buf], { type: buftype });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
        "download",
        `AHA - ${programName} - Dashboard Data - ${date}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const registryName = (programData: any) => {
    const programDetails = programData?.programDetails
        ? JSON.parse(programData?.programDetails)
        : "";
    const jsonData = programDetails ? programDetails.jsonForm : [];
    if (
        jsonData.length > 0 &&
        jsonData[jsonData.length - 1].customData === true
    ) {
        return jsonData[jsonData.length - 1].registryName;
    }
    return "";
};

export async function modifyDashBordTemplate(
    document: any,
    hospital: any,
    programData: any,
    certificateData: any
) {
    if (!document) return;
    const registry: any = registryName(programData);
    document.getElementById("bannerTitle").innerHTML =
        hospital && hospital.facilityName ? `${hospital.facilityName} ` : "N/A";
    if (certificateData.programCertificateGrantedOn) {
        document.getElementById("bannerDate").innerHTML =
            `<img src="./../../../../../../../dashboard-export-template/badge.png" alt="badge">
             Certified on: ${localDateToYYYYMMDDFormatString(
                 certificateData.programCertificateGrantedOn
             )}` || "N/A";
    } else {
        document.getElementById("bannerDate").style.display = "none";
    }
    document.getElementById("facilityCode").innerHTML =
        hospital.facilityCode || "N/A";
    document.getElementById("countryName").innerHTML =
        hospital.countryName || "N/A";
    document.getElementById("registeredOn").innerHTML =
        localDateToYYYYMMDDFormatString(hospital.createdAt) || "N/A";
    document.getElementById("programName").innerHTML =
        programData?.programCategoryName || "N/A";
    if (programData?.corporationName) {
        document.getElementById("corporation").innerHTML =
            programData?.corporationName;
    } else {
        document.getElementById("corpBanner").style.display = "none";
    }
    if (registry) {
        document.getElementById("registry").innerHTML = registry;
    } else {
        document.getElementById("regBanner").style.display = "none";
    }
}

export async function getCanvas(element: any, document: any, distance: any) {
    const canvasElement = html2canvas(document.getElementById(element), {
        scale: 2,
    });
    return { canvasElement, distance };
}

export async function exportDashBoardPdf(
    nextPosition: any,
    document: any,
    chartDetails: any,
    measureMetric: any,
    isMeasure: Boolean,
    capturedImages: any,
    isEup: any
) {
    const colorList = getColorForSeries();
    for (let index = 0; index < chartDetails.length; index++) {
        if (!chartDetails[index].hideInd) {
            const groupName = isMeasure ? "measureName" : "metricName";
            const graphContainer = isMeasure
                ? "graphContainer"
                : "graphContainerMetric";
            let measureMetricObject = measureMetric;
            let benchMark = [];
            let measureMetricCodeList = [];
            if (isMeasure) {
                measureMetricObject = measureMetric.measure;
                measureMetricCodeList = measureMetric.isAnnual
                    ? measureMetricObject[index].codes
                    : measureMetricObject[index].measureCodes;
            } else {
                measureMetricObject = measureMetric.metric;
                measureMetricCodeList = measureMetricObject[index].metricsCodes;
            }
            const colorCodeList = measureMetricCodeList.reduce(
                (acc: any, curr: any, i: any) => {
                    acc[curr] = colorList[i].color;
                    return acc;
                },
                {}
            );
            if (chartDetails[index].isBenchMark) {
                benchMark = measureMetric.benchmarkValues?.filter(
                    (benchMarkObj: any) =>
                        chartDetails[index].groupName ===
                        benchMarkObj[groupName]
                );
                document.getElementById(
                    graphContainer
                ).innerHTML = `<div class=${
                    isEup ? "benchmark-legend" : "benchmark-legend-admin"
                }>
                <div class="benchmark-legend-block"></div>
                Benchmark
            </div>
                <img src="${chartDetails[index].URI}" alt="">`;
                document.getElementById(
                    "measureTableLable"
                ).innerHTML = `N (Numerator), D (Denominator), P (Percentage), BM (Benchmark)`;
            } else {
                document.getElementById(
                    graphContainer
                ).innerHTML = `<img src="${chartDetails[index].URI}" alt="">`;
            }
            const measureMetricCodeObject = measureMetricObject.filter(
                (measureMetricObj: any) =>
                    chartDetails[index].groupName ===
                    measureMetricObj[groupName]
            );
            const measureCodeAll = chartDetails[index].filteredCodes.map(
                (codeObj: any) => codeObj.label
            );
            const measureCode = measureCodeAll.filter(
                (code: any) => code !== "All"
            );
            const toolTip = measureCode.reduce((acc: any, curr: any) => {
                acc[curr] = measureMetric.measureMetricTooltip[curr];
                return acc;
            }, {});
            if (isMeasure && measureMetric.isAnnual) {
                const years: any = [];
                for (
                    let year = measureMetric.from;
                    year <= measureMetric.to;
                    year++
                ) {
                    years.push(year);
                }
                let columnHead = '<th scope="col">Period</th>';
                let progarmGroupLabel = ``;
                measureCode.forEach((element: any) => {
                    columnHead += `<th scope="col" style="width: ${
                        85 / measureCode.length
                    }%">
                        <div class="table-header">
                            <div class="legend-symbol" style="background-color: ${
                                colorCodeList[element]
                            };"></div>
                            <span>${element}</span>
                        </div>
                    </th>`;
                    progarmGroupLabel += `
                        <div class="program-group-label">
                            <div>${element}:</div>
                            ${toolTip[element]}
                    </div>`;
                });
                document.getElementById("pgMeasure").innerHTML =
                    chartDetails[index].groupName;
                capturedImages.push(getCanvas("pgMeasure", document, 1));
                document.getElementById("programGroupLabel").innerHTML =
                    progarmGroupLabel;
                capturedImages.push(
                    getCanvas("programGroupLabel", document, 1)
                );
                capturedImages.push(getCanvas("graphContainer", document, 0));
                document.getElementById("columnHead").innerHTML = columnHead;
                capturedImages.push(getCanvas("theadMeasure", document, 3));
                nextPosition -= 3;
                for (let item = 0; item < years.length; item++) {
                    let rowValue = "";
                    const annual = measureMetricCodeObject[0].measureData[
                        years[item]
                    ]
                        ? measureMetricCodeObject[0].measureData[years[item]]
                        : [];
                    const benchmarkValues: any[] =
                        chartDetails[index].isBenchMark &&
                        benchMark.length &&
                        benchMark[0].measureData[years[item]]
                            ? benchMark[0].measureData[years[item]]
                            : [];
                    rowValue = `<td id="quarterDetails">${`${years[item]}`}</td>`;
                    for (let row = 0; row < measureCode.length; row++) {
                        const annualValueIndex = annual.findIndex(
                            (annual: any) => measureCode[row] === annual.code
                        );
                        const benchmarkIndex = benchmarkValues.findIndex(
                            (benchmark: any) =>
                                measureCode[row] === benchmark.code
                        );
                        if (annualValueIndex > -1 || benchmarkIndex > -1) {
                            let value: string | number = "";
                            let benchMarkValue;
                            let numerator = annual[annualValueIndex]?.numerator;
                            let denominator =
                                annual[annualValueIndex]?.denominator;
                            if (numerator && denominator) {
                                value = (numerator / denominator) * 100;
                                value = `P: ${value.toFixed(2)}%`;
                            } else if (
                                (numerator == 0 && denominator == 0) ||
                                (numerator == 0 && denominator)
                            ) {
                                value = "P: 0.00%";
                            }
                            numerator =
                                numerator != null ? `N: ${numerator} ` : "";
                            denominator =
                                denominator != null ? `D: ${denominator}` : "";
                            benchMarkValue = "";
                            if (chartDetails[index].isBenchMark) {
                                benchMarkValue =
                                    benchmarkValues[benchmarkIndex]
                                        ?.annualBenchmark;
                                benchMarkValue = benchMarkValue
                                    ? `BM: ${benchMarkValue.toFixed(2)}%`
                                    : benchMarkValue == 0
                                    ? "BM: 0.00%"
                                    : "";
                            }
                            rowValue += `<td style="width: ${
                                85 / measureCode.length
                            }%">
                        <div class="table-value-nd">
                            <span>${numerator}</span>
                            <span>${denominator}</span>
                        </div>
                        <div>${value}</div>
                        <div>${benchMarkValue}</div>
                    </td>`;
                        } else {
                            rowValue += `<td style="width: ${
                                85 / measureCode.length
                            }%">
                        <div class="table-value-nd">
                            <span>${""}</span>
                    </td>`;
                        }
                    }
                    document.getElementById("rowMeasure").innerHTML = rowValue;
                    capturedImages.push(getCanvas("tbodyMeasure", document, 0));
                }
            }
            if (!measureMetric.isAnnual) {
                const programGroup = isMeasure ? "pgMeasure" : "pgMetric";
                document.getElementById(programGroup).innerHTML =
                    chartDetails[index].groupName;
                capturedImages.push(getCanvas(programGroup, document, 1));
                let columnHead =
                    '<th scope="col" style="width:15%">Period</th>';
                let progarmGroupLabel = ``;
                for (let code = 0; code < measureCode.length; code++) {
                    columnHead += `<th scope="col" style="width: ${
                        85 / measureCode.length
                    }%">
                        <div class="table-header">
                            <div class="legend-symbol" style="background-color: ${
                                colorCodeList[measureCode[code]]
                            };"></div>
                            <span>${measureCode[code]}</span>
                        </div>
                    </th>`;
                    progarmGroupLabel += `
                        <div class="program-group-label">
                            <div>${measureCode[code]}:</div>
                            ${toolTip[measureCode[code]]}
                    </div>`;
                }
                document.getElementById("programGroupLabel").innerHTML =
                    progarmGroupLabel;
                capturedImages.push(
                    getCanvas("programGroupLabel", document, 1)
                );
                capturedImages.push(getCanvas(graphContainer, document, 0));
                const columnHeadear = isMeasure
                    ? "columnHead"
                    : "columnHeadMetric";
                const tableHeader = isMeasure ? "theadMeasure" : "theadMetric";
                document.getElementById(columnHeadear).innerHTML = columnHead;
                capturedImages.push(getCanvas(tableHeader, document, 3));
                const quarterList = getAllQuarterList(
                    measureMetric.fromYear,
                    measureMetric.toYear,
                    measureMetric.fromQuarter,
                    measureMetric.toQuarter
                );
                for (let item = 0; item < quarterList.length; item++) {
                    const quarterDetails = [];
                    const benchMarkDetails = [];
                    let quater = measureMetricCodeObject[0].Q1.filter(
                        (el: any) => el.quarterDetails == quarterList[item]
                    );
                    quarterDetails.push(...quater);
                    quater = measureMetricCodeObject[0].Q2.filter(
                        (el: any) => el.quarterDetails == quarterList[item]
                    );
                    quarterDetails.push(...quater);
                    quater = measureMetricCodeObject[0].Q3.filter(
                        (el: any) => el.quarterDetails == quarterList[item]
                    );
                    quarterDetails.push(...quater);
                    quater = measureMetricCodeObject[0].Q4.filter(
                        (el: any) => el.quarterDetails == quarterList[item]
                    );
                    quarterDetails.push(...quater);
                    if (chartDetails[index].isBenchMark) {
                        let quater: any[] = benchMark[0].Q1.filter(
                            (el: any) => el.quarterDetails == quarterList[item]
                        );
                        benchMarkDetails.push(...quater);
                        quater = benchMark[0].Q2.filter(
                            (el: any) => el.quarterDetails == quarterList[item]
                        );
                        benchMarkDetails.push(...quater);
                        quater = benchMark[0].Q3.filter(
                            (el: any) => el.quarterDetails == quarterList[item]
                        );
                        benchMarkDetails.push(...quater);
                        quater = benchMark[0].Q4.filter(
                            (el: any) => el.quarterDetails == quarterList[item]
                        );
                        benchMarkDetails.push(...quater);
                    }
                    const rowElement = isMeasure ? "rowMeasure" : "rowMetric";
                    const tableBody = isMeasure
                        ? "tbodyMeasure"
                        : "tbodyMetric";
                    let rowValue = `<td id="quarterDetails">${quarterList[item]}</td>`;
                    if (quarterDetails.length || benchMarkDetails.length) {
                        for (let row = 0; row < measureCode.length; row++) {
                            const quaterValueIndex = quarterDetails.findIndex(
                                (quarter: any) =>
                                    measureCode[row] === quarter.code
                            );
                            const benchValueIndex = benchMarkDetails.findIndex(
                                (quarter: any) =>
                                    measureCode[row] === quarter.code
                            );
                            let numerator;
                            let denominator;
                            let benchMarkValue;
                            let value;
                            if (isMeasure) {
                                benchMarkValue =
                                    benchMarkDetails[benchValueIndex]
                                        ?.BenchmarkValue;
                                benchMarkValue = benchMarkValue
                                    ? `BM: ${benchMarkValue.toFixed(2)}%`
                                    : benchMarkValue == 0
                                    ? "BM: 0.00%"
                                    : "";
                                numerator =
                                    quarterDetails[quaterValueIndex]?.numerator;
                                denominator =
                                    quarterDetails[quaterValueIndex]
                                        ?.denominator;
                                value = isNaN(
                                    quarterDetails[quaterValueIndex]?.value
                                )
                                    ? ""
                                    : `P: ${quarterDetails[quaterValueIndex]?.value}%`;
                                if (numerator == 0 && denominator == 0) {
                                    value = "P: 0.00%";
                                }
                                numerator =
                                    numerator != null ? `N: ${numerator} ` : "";
                                denominator =
                                    denominator != null
                                        ? `D: ${denominator}`
                                        : "";
                                rowValue += `<td style="width: ${
                                    85 / measureCode.length
                                }%">
                            <div class="table-value-nd">
                                <span>${numerator}</span>
                                <span>${denominator}</span>
                            </div>
                            <div>${value}</div>
                            <div>${benchMarkValue}</div>
                        </td>`;
                            } else {
                                value = quarterDetails[quaterValueIndex]?.value;
                                if (value || value == 0) {
                                    value =
                                        quarterDetails[quaterValueIndex]
                                            ?.type == "Percentage"
                                            ? `${value}%`
                                            : `${value}`;
                                } else {
                                    value = "";
                                }
                                rowValue += `<td style="width: ${
                                    85 / measureCode.length
                                }%">
                            <div>${value}</div>
                        </td>`;
                            }
                        }
                        document.getElementById(rowElement).innerHTML =
                            rowValue;
                        capturedImages.push(getCanvas(tableBody, document, 0));
                    } else {
                        for (let row = 0; row < measureCode.length; row++) {
                            rowValue += `<td style="width: ${
                                85 / measureCode.length
                            }%">
                        <div class="table-value-nd">
                            <span>${""}</span>
                            <span>${""}</span>
                        </div>
                        <div>${""}</div>
                        <div>${""}</div>
                    </td>`;
                        }
                        document.getElementById(rowElement).innerHTML =
                            rowValue;
                        capturedImages.push(getCanvas(tableBody, document, 0));
                    }
                }
            }
        }
    }
    return nextPosition;
}

export const isChartVisible = (measureMetricChartDetails: any) => {
    let allMeasureMetricChartHidden = true;
    for (let index = 0; index < measureMetricChartDetails.length; index++) {
        if (!measureMetricChartDetails[index].hideInd) {
            allMeasureMetricChartHidden = false;
            return allMeasureMetricChartHidden;
        }
    }
    return allMeasureMetricChartHidden;
};

const getAllQuarterList = (
    fromYear: any,
    toYear: any,
    fromQuarter: any,
    toQuarter: any
) => {
    const quartersList = [];
    const quarters = ["Q1", "Q2", "Q3", "Q4"];
    if (fromYear === toYear) {
        for (
            let formquarterIndex = fromQuarter;
            formquarterIndex <= toQuarter;
            formquarterIndex++
        ) {
            quartersList.push(`${fromYear}-${quarters[formquarterIndex - 1]}`);
        }
    } else {
        for (let yearIndex = fromYear; yearIndex <= toYear; yearIndex++) {
            if (yearIndex === fromYear) {
                for (
                    let formquarterIndex = fromQuarter;
                    formquarterIndex <= 4;
                    formquarterIndex++
                ) {
                    quartersList.push(
                        `${yearIndex}-${quarters[formquarterIndex - 1]}`
                    );
                }
            } else if (yearIndex === toYear) {
                for (
                    let toquarterIndex = 1;
                    toquarterIndex <= toQuarter;
                    toquarterIndex++
                ) {
                    quartersList.push(
                        `${yearIndex}-${quarters[toquarterIndex - 1]}`
                    );
                }
            } else {
                for (let quarterIndex = 1; quarterIndex <= 4; quarterIndex++) {
                    quartersList.push(
                        `${yearIndex}-${quarters[quarterIndex - 1]}`
                    );
                }
            }
        }
    }
    return quartersList;
};

export const renderTableData = (
    measureMetric: any,
    quarterAnnualIndex: any,
    filterLabel: any,
    metricType: any,
    interval: any,
    benchMark: any
) => {
    let numerator;
    let denominator;
    let value;
    let benchMarkValue;
    if (interval == CONSTANTS.INTERVAL.QUARTERLY) {
        numerator = measureMetric[quarterAnnualIndex]?.numerator;
        denominator = measureMetric[quarterAnnualIndex]?.denominator;
        const actualValue = measureMetric[quarterAnnualIndex]?.actualValue;
        if (filterLabel === "Measure ID") {
            benchMarkValue =
                benchMark.benchMarkDetail[benchMark.benchMarkIndex]
                    ?.actualValue;
            value = isNaN(actualValue) ? "" : `${actualValue}%`;
            if (numerator == 0 && denominator == 0) {
                value = "0.00%";
            }
            numerator = numerator != null ? `${numerator} ` : "";
            denominator = denominator != null ? `${denominator}` : "";
            benchMarkValue = benchMarkValue
                ? `${benchMarkValue.toFixed(2)}%`
                : benchMarkValue == 0
                ? "0.00%"
                : "";
        }
        if (filterLabel === "Metric ID" || filterLabel === "Sub-metric ID") {
            value = actualValue;
            if (value || value == 0) {
                value = metricType == "Percentage" ? `${value}%` : `${value}`;
            } else {
                value = "";
            }
        }
    }
    if (interval == CONSTANTS.INTERVAL.ANNUAL && filterLabel === "Measure ID") {
        benchMarkValue =
            benchMark.benchMarkDetail[benchMark.benchMarkIndex]?.actualValue;
        benchMarkValue = benchMarkValue
            ? `${benchMarkValue.toFixed(2)}%`
            : benchMarkValue == 0
            ? "0.00%"
            : "";
        numerator = measureMetric[quarterAnnualIndex]?.numerator;
        denominator = measureMetric[quarterAnnualIndex]?.denominator;
        const actualValue = measureMetric[quarterAnnualIndex]?.value;
        value = actualValue ? `${actualValue.toFixed(2)}%` : ": 0.00%";
        if (numerator == null || denominator == null) {
            value = "";
        }
        numerator = numerator != null ? `${numerator} ` : "";
        denominator = denominator != null ? `${denominator}` : "";
    }
    return { numerator, denominator, value, benchMarkValue };
};

export const setMeasureCodeAndColorObject = (
    filterLabel: any,
    interval: any,
    filterListSelected: any,
    allIdsList: any
) => {
    const colorList = getColorForSeries();
    let hideMetric: boolean = false;
    const ids = `${filterListSelected.map(
        (filteredItem: any) => filteredItem.label
    )}`;
    const selIds = allIdsList.filter(
        (selectedId: any) => ids.indexOf(selectedId.label) > -1
    );
    const measureCodeAll = selIds.map((codeObj: any) => codeObj.label);
    const measureCode = measureCodeAll.filter((code: any) => code !== "All");
    if (
        (filterLabel === "Metric ID" || filterLabel === "Sub-metric ID") &&
        interval === CONSTANTS.INTERVAL.ANNUAL
    ) {
        hideMetric = true;
    } else {
        hideMetric = false;
    }
    const allMeasureCode = allIdsList.filter(
        (allId: any) => allId.label != "All"
    );
    const allMeasureCodeLabel = allMeasureCode.map(
        (codeObj: any) => codeObj.label
    );
    const colorCodeList = allMeasureCodeLabel.reduce(
        (acc: any, curr: any, i: any) => {
            acc[curr] = colorList[i].color;
            return acc;
        },
        {}
    );
    return { hideMetric, measureCode, colorCodeList };
};

export const gobalExportFile = (
    url: any,
    programName: any,
    selectedYear: any
) => {
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            const dateString = new Date();
            const year = dateString.getFullYear();
            const month = dateString
                .toLocaleString("default", { month: "short" })
                .substring(0, 3);
            const day = String(dateString.getDate()).padStart(2, "0");
            const filename = `AHA - ${programName} - ${selectedYear} Program Data- ${day}-${month}-${year}.xlsx`;
            const url = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.download = filename;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        });
};

export const sortIndBooleanValue = (value: any, tab: any) => {
    if (
        tab !== "corporationFaciltyCertified" &&
        (value === CONSTANTS.EXPIRY_ASCENDING ||
            value === CONSTANTS.EXPIRY_DESCENDING)
    ) {
        return undefined;
    }
    if (value === CONSTANTS.ASCENDING || value === CONSTANTS.EXPIRY_ASCENDING) {
        return true;
    }
    if (value === CONSTANTS.DEFAULT) {
        return undefined;
    }
    if (
        value === CONSTANTS.DESCENDING ||
        value === CONSTANTS.EXPIRY_DESCENDING
    ) {
        return false;
    }
};

export const cstDateFormat = (date: any) => {
    const months: any = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
    };
    if (date) {
        const today = date.split("-");
        const key = Object.keys(months).filter((key) => {
            return today[1] === months[key];
        })[0];
        const formatedDate = `${today[0]}-${key}-${today[2]}`;
        return formatedDate;
    }
    return "";
};

export const localDateTimeToHHMMSSString = (dateTimeString: any) => {
    if (typeof dateTimeString === "string") {
        const date = new Date(dateTimeString);
        if (!isNaN(date.getTime())) {
            return `${String(date.getUTCHours()).padStart(2, "0")}:${String(
                date.getUTCMinutes()
            ).padStart(2, "0")}:${String(date.getUTCSeconds()).padStart(
                2,
                "0"
            )}`;
        }
        return "";
    }
    return "";
};

export const validateSpecialCharGreaterLessThan = (value: any) => {
    if (value.includes(">") || value.includes("<")) return false;
    return true;
};
