import React, { useEffect, useState, useRef } from "react";
import { HospitalManagementDetailsWrapper } from "pages/Admin/RegisteredHospitalDetails/styled";
import { useParams, useLocation } from "react-router-dom";
import HospitalMeasuresTab from "pages/Admin/HospitalMeasuresTab/HospitalMeasuresTab";
import HospitalCertificationTab from "pages/Admin/HospitalCertificationTab/HospitalCertificationTab";
import { useDispatch, useSelector } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import { getHospitalById } from "api/hospitalApi";
import { getHospitalProgramMeasureMetrics } from "api/measuresApi";
import {
    getCurrentQuarter,
    getQuarterStartAndEndDate,
    showModal,
} from "common/utils";
import CONSTANTS from "common/constants";
import { BreadCrumb } from "components/BreadCrumb";
import {
    setQuarter,
    setYear,
} from "components/MeasuresDateFilter/measuresDateFilter.slice";
import MoreActions from "components/MoreActions/MoreActions";
import { approveHospital, deactivateHospital } from "api/adminDashboardAPI";
import { showToast } from "components/Toast/toast.slice";
import FocusTrap from "focus-trap-react";
import { getHospitalProgramCertificate } from "../../../api/certificateAPI";
import config from "../../../config";
import { logger } from "../../../utils/logger.utils";
import EMSAdminSidebar from "../Sidebar/Sidebar";
import OrganizationDetails from "../OrganizationDetails/OrganizationDetails";
import OrganizationCertificationTab from "../OrganizationCertificationTab/OrganizationCertificationTab";

export interface IMeasure {
    measureId: number;
    measureName: string;
    description: string;
    numerator: number;
    denominator: number;
}

export const RegisteredEMSHospitalDetails = () => {
    // REDUX variables
    const dispatch = useDispatch();
    const location = useLocation();
    const childRef = useRef();
    const measuresDateFilter = useSelector((state: any) => {
        return state.measuresDateFilter;
    });

    // ROUTE VARIABLES
    const { hospitalId, programId, categoryId }: any = useParams();
    const facilityName = location.state?.facilityName
        ? location.state?.facilityName
        : null;

    // RFC STATE
    const [hospital, setHospital] = useState<any>({});
    const [programName, setProgramName] = useState<any>();
    const [programData, setProgramData] = useState<any>();
    const [programDataCertificationTab, setProgramDataCertificationTab] =
        useState<any>();
    const [certificateData, setCertificateData] = useState<any>();
    const [returnPath, setReturnPath] = useState<any>(null);
    const [isMeasureTableLoading, setIsMeasureTableLoading] =
        useState<boolean>(false);
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [hospitalMeasureMetrics, setHospitalMeasureMetrics] = useState<any>();
    const [measuresInd, setMeasuresInd] = useState<boolean>(false);
    const [awardInd, setAwardInd] = useState<boolean>(false);
    const [documentsInd, setDocumentsInd] = useState<boolean>(false);
    const [certificateInd, setCertificateInd] = useState<boolean>(false);
    const [detailsInd, setDetailsInd] = useState<boolean>(false);
    const [deactivateFacilityActivateInd, setDeactivateFacilityActivateInd] =
        useState<boolean>(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    useEffect(() => {
        getFacilityDetails();
        const elementsArray: any = [];
        let pathToReturn: any;
        if (location?.state?.backToOrganizationManagement) {
            pathToReturn = {
                pathname: location?.state?.backToOrganizationManagement,
                state: {
                    adminOrganizationMangemnetTab:
                        location?.state?.adminOrganizationMangemnetTab,
                    adminOrganizationMangemnetPage:
                        location?.state?.adminOrganizationMangemnetPage,
                    pageSort: location?.state?.pageSort,
                },
            };
            setReturnPath(pathToReturn);
            elementsArray.push(
                {
                    label:
                        location?.state?.adminOrganizationMangemnetTab ==
                        CONSTANTS.CERTIFIED
                            ? "Awarded"
                            : "Registered",
                    returnPath: pathToReturn,
                },
                {
                    label: facilityName,
                    returnPath: pathToReturn,
                }
            );
            setBreadcrumbItems(elementsArray);
        }
    }, []);

    const getFacilityDetails = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const startDateAndEndDate = getQuarterStartAndEndDate(
            measuresDateFilter.year,
            measuresDateFilter.quarter,
            true
        );
        if (startDateAndEndDate) {
            Promise.all([
                getHospitalById(hospitalId),
                getHospitalProgramDetails(programId, hospitalId),
                getHospitalProgramCertificate(
                    hospitalId,
                    programId,
                    categoryId
                ),
            ])
                .then((response: any) => {
                    if (
                        response[0].success &&
                        response[1].success &&
                        response[2].success
                    ) {
                        setHospital(response[0].data.hospital);
                        localStorage.setItem(
                            "selectedHospitalCountryCode",
                            response[0].data.hospital.countryCode
                        );
                        setProgramName(response[1].data.programName);
                        setProgramData(response[1].data);
                        const emsData = {
                            emsId: response[1].data.emsId,
                            verifiedBy: response[1].data.verifiedBy,
                            approvedDate: response[1].data.approvedDate,
                            data: response[1].data,
                        };
                        setProgramDataCertificationTab(emsData);
                        setCertificateData(response[2].data.programCertficate);
                        setDetailsInd(false);
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                })
                .catch(() => {
                    setDetailsInd(false);
                    dispatch(setPageLoadingStatus({ isPageLoading: true }));
                });
        }
    };

    useEffect(() => {
        if (detailsInd) {
            getFacilityDetails();
        }
    }, [detailsInd]);

    useEffect(() => {
        const fetchData = async () => {
            // Prevent multiple API calls if already fetching
            if (isFetchingData) return;

            // Set fetching state to true to indicate the start of API calls
            setIsFetchingData(true);

            try {
                if (measuresInd && measuresDateFilter.year) {
                    dispatch(setPageLoadingStatus({ isPageLoading: true }));
                    setIsMeasureTableLoading(true);
                    dispatch(setYear(measuresDateFilter.year));
                    dispatch(setQuarter(getCurrentQuarter()));

                    const startDateAndEndDate = getQuarterStartAndEndDate(
                        measuresDateFilter.year,
                        getCurrentQuarter(),
                        true
                    );
                    if (startDateAndEndDate) {
                        setHospitalMeasureMetrics({
                            ...hospitalMeasureMetrics,
                            measuresData: [],
                            metricsData: [],
                        });
                        const [hospitalResponse, measureMetricsResponse] =
                            await Promise.all([
                                getHospitalById(hospitalId),
                                getHospitalProgramMeasureMetrics(
                                    hospitalId,
                                    programId,
                                    categoryId,
                                    startDateAndEndDate?.split("/")[0],
                                    startDateAndEndDate?.split("/")[1]
                                ),
                            ]);

                        if (
                            hospitalResponse.success &&
                            measureMetricsResponse.success
                        ) {
                            setHospital(hospitalResponse.data.hospital);
                            setHospitalMeasureMetrics(
                                measureMetricsResponse.data
                            );
                        }
                    }
                }
                // Separate logic for measuresDateFilter.year without measuresInd
                else if (measuresDateFilter.year) {
                    setIsMeasureTableLoading(true);
                    const startDateAndEndDate = getQuarterStartAndEndDate(
                        measuresDateFilter.year,
                        measuresDateFilter.quarter,
                        true
                    );

                    if (startDateAndEndDate) {
                        setHospitalMeasureMetrics({
                            ...hospitalMeasureMetrics,
                            measuresData: [],
                            metricsData: [],
                        });
                        const [measureMetricsResponse] = await Promise.all([
                            getHospitalProgramMeasureMetrics(
                                hospitalId,
                                programId,
                                categoryId,
                                startDateAndEndDate?.split("/")[0],
                                startDateAndEndDate?.split("/")[1]
                            ),
                        ]);

                        if (measureMetricsResponse.statusCode === 200) {
                            setHospitalMeasureMetrics(
                                measureMetricsResponse.data
                            );
                        }
                    }
                }
            } catch (error) {
                logger(error);
            } finally {
                setIsMeasureTableLoading(false);
                setMeasuresInd(false);
                setIsFetchingData(false); // Reset fetching state after completion
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            }
        };

        fetchData();
    }, [measuresInd, measuresDateFilter.year]);

    useEffect(() => {
        if (documentsInd || certificateInd) {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            Promise.all([getHospitalById(hospitalId)])
                .then((response: any) => {
                    setDocumentsInd(false);
                    setCertificateInd(false);
                    if (response[0].success && response[1].success) {
                        setHospital(response[0].data.hospital);
                    }
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                })
                .catch(() => {
                    setDocumentsInd(false);
                    setCertificateInd(false);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    }, [documentsInd, certificateInd]);

    const sortDropdown = [
        {
            label: CONSTANTS.EXPORT,
            value: CONSTANTS.EXPORT,
            className: "aha-icon-export menu-cst-icon",
        },
        {
            label: CONSTANTS.DEACTIVATE,
            value: CONSTANTS.DEACTIVATE,
            className: "aha-icon-stop font-red mr-2",
        },
    ];

    const callParentOnSelect = (value: any) => {
        if (value === CONSTANTS.EXPORT) {
            return exportFunctionEMSFacility();
        }
        if (value === CONSTANTS.DEACTIVATE) {
            return deactivateFacilityConfirmationPopupModal();
        }
    };

    const exportFunctionEMSFacility = () => {
        if (childRef.current) {
            const childRefCurrent = childRef.current as {
                exportFacilityData: () => void;
            };
            childRefCurrent.exportFacilityData();
        }
    };

    const deactivateFacility = async () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const deactivateResult = await deactivateHospital(
            hospital.facilityCode,
            false
        );
        if (deactivateResult.success) {
            const toast = {
                message: `${hospital.facilityName} has been deactivated.`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            close();
            deactivateFacilityConfirmationDismissModal();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
    };

    const deactivateFacilityConfirmationPopupModal = () => {
        setDeactivateFacilityActivateInd(true);
        showModal();
    };
    const deactivateFacilityConfirmationDismissModal = () => {
        setDeactivateFacilityActivateInd(false);
        showModal();
    };

    return (
        <HospitalManagementDetailsWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <EMSAdminSidebar activeTab="organizationManagement" />

                <section className="d-flex admin-main-content container select-text">
                    <div className="row no-gutters w-100 admin-main">
                        <BreadCrumb breadcrumbItems={breadcrumbItems} />
                        <div className="col-12 navbar-expand-lg">
                            <div className="hospital-mgnt-header d-sm-flex justify-content-between align-items-center mb-4">
                                <h1 className="h2 mb-0 font-400">
                                    Organization Management
                                </h1>
                                <div className="d-flex">
                                    <div
                                        id="moreactions"
                                        className="d-flex align-items-center more-actions"
                                    >
                                        <div
                                            className="export-dropdown ml-2"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="More Actions"
                                            data-container="#moreactions"
                                        >
                                            <MoreActions
                                                sortDropdown={sortDropdown}
                                                callParentOnSelect={
                                                    callParentOnSelect
                                                }
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="navbar-toggler ml-3"
                                        data-toggle="collapse"
                                        data-target="#navbarManagement"
                                        aria-controls="navbarManagement"
                                        aria-expanded="false"
                                        aria-label="navigation Menu"
                                    >
                                        <i
                                            className="aha-icon-hamburger"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                                <button
                                    type="button"
                                    className="aui-navbar-toggler navbar-toggler btn btn-round btn-secondary"
                                    data-toggle="collapse"
                                    data-target="#navbarManagement"
                                    aria-controls="navbarManagement"
                                    aria-expanded="false"
                                    aria-label="Menu Toggle navigation"
                                >
                                    Menu
                                </button>
                            </div>
                            <div className="aui-p-tabs" role="tablist">
                                <div
                                    id="navbarManagement"
                                    className="collapse navbar-collapse"
                                >
                                    <ul className="nav nav-tabs mb-4">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                data-toggle="tab"
                                                href="#detailstab"
                                                role="tab"
                                                onClick={() => {
                                                    setDetailsInd(true);
                                                    setAwardInd(false);
                                                }}
                                            >
                                                Details
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                data-toggle="tab"
                                                href="#measurestab"
                                                role="tab"
                                                onClick={() => {
                                                    setMeasuresInd(true);
                                                    setAwardInd(false);
                                                }}
                                            >
                                                Measures
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                data-toggle="tab"
                                                href="#certificationtab"
                                                role="tab"
                                                onClick={() =>
                                                    setAwardInd(true)
                                                }
                                            >
                                                Award
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/* Deactivate Modal */}
                                {(hospital.approvalStatus ==
                                    CONSTANTS.REGISTRED ||
                                    hospital.approvalStatus ==
                                        CONSTANTS.RENEWING ||
                                    hospital.approvalStatus ==
                                        CONSTANTS.AWARDED) &&
                                    hospital.isActive == true &&
                                    deactivateFacilityActivateInd && (
                                        <FocusTrap
                                            focusTrapOptions={{
                                                escapeDeactivates: false,
                                                clickOutsideDeactivates: false,
                                            }}
                                        >
                                            <div
                                                className="modal fade show aui-modal"
                                                tabIndex={-1}
                                                aria-labelledby="detailsConfirmDeactiveModal"
                                                aria-modal="true"
                                                role="dialog"
                                                style={{
                                                    display:
                                                        deactivateFacilityActivateInd
                                                            ? "block"
                                                            : "none",
                                                }}
                                            >
                                                <div className="modal-dialog modal-dialog-centered modal-sm">
                                                    <div className="modal-content">
                                                        <div className="modal-body p-0">
                                                            <div className="text-center">
                                                                <span
                                                                    id="detailsConfirmDeactiveModal"
                                                                    className="sr-only"
                                                                >
                                                                    Confirm
                                                                    Deactivate
                                                                    Organization
                                                                </span>
                                                                <p className="pb-2">
                                                                    Are you sure
                                                                    you want to
                                                                    deactivate&nbsp;
                                                                    <b>
                                                                        {
                                                                            hospital.facilityName
                                                                        }
                                                                    </b>{" "}
                                                                    organization?
                                                                </p>
                                                                <div className="mt-4 d-flex justify-content-center">
                                                                    <button
                                                                        className="btn btn-secondary btn-round btn-sm mx-2"
                                                                        aria-label="Cancel Deactivation and close the modal"
                                                                        onClick={
                                                                            deactivateFacilityConfirmationDismissModal
                                                                        }
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-primary btn-round btn-sm mx-2"
                                                                        onClick={
                                                                            deactivateFacility
                                                                        }
                                                                        data-dismiss="modal"
                                                                        aria-label="Confirm Deactivate"
                                                                    >
                                                                        Confirm
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </FocusTrap>
                                    )}
                                <div className="tab-content">
                                    <div
                                        id="detailstab"
                                        className="tab-pane active py-lg-3 fac-detail-tab"
                                        role="tabpanel"
                                    >
                                        <OrganizationDetails
                                            hospital={hospital}
                                            returnPath={returnPath}
                                            programData={programData}
                                            certificateData={certificateData}
                                            updateHospitalInfo={(
                                                updatedHospitalInfo: any
                                            ) => {
                                                setHospital(
                                                    updatedHospitalInfo
                                                );
                                            }}
                                            ref={childRef}
                                        />
                                    </div>
                                    <div
                                        id="measurestab"
                                        className="tab-pane fade py-lg-3"
                                        role="tabpanel"
                                    >
                                        <HospitalMeasuresTab
                                            hospital={hospital}
                                            programName={programName}
                                            hospitalMeasures={
                                                hospitalMeasureMetrics
                                            }
                                            isMeasureTableLoading={
                                                isMeasureTableLoading
                                            }
                                            programData={programData}
                                            certificateData={certificateData}
                                            emsAdminInd
                                        />
                                    </div>
                                    <div
                                        id="certificationtab"
                                        className="tab-pane fade py-lg-3"
                                        role="tabpanel"
                                    >
                                        <OrganizationCertificationTab
                                            hospital={hospital}
                                            programName={programName}
                                            programData={
                                                programDataCertificationTab
                                            }
                                            certificateData={certificateData}
                                            programDetails={programData}
                                            awardInd={awardInd}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </HospitalManagementDetailsWrapper>
    );
};

export default RegisteredEMSHospitalDetails;
